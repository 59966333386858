import { Modal } from "react-bootstrap";
import {
  Card,
  Stack,
  Button,
  Popover,
  Container,
  Typography,
  Grid,
} from '@mui/material';
import moment from "moment";

export default function UserSessionDetailModal({ data, onHide }) {

  return (
    <Modal show={data} style={{ zIndex: 10001 }} size="xl" scrollable onHide={onHide}>
      <Modal.Body>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
            <Typography variant="h4" gutterBottom>
              Details - {data?.full_name}
            </Typography>
          </Stack>

          <Card sx={{padding: '2rem'}}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={4}>
                <Typography variant="overline" gutterBottom>
                  Full Name
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.full_name}
                </Typography>
              </Grid>
              <Grid item sm={12} md={4}>
                <Typography variant="overline" gutterBottom>
                  Company
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.company}
                </Typography>
              </Grid>
              <Grid item sm={12} md={4}>
                <Typography variant="overline" gutterBottom>
                  Job Title
                </Typography>
                <Typography variant="body1" gutterBottom>
                {data?.job_title}
                </Typography>
              </Grid>
              <Grid item sm={12} md={4}>
                <Typography variant="overline" gutterBottom>
                  Industry
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.industry}
                </Typography>
              </Grid>
              <Grid item sm={12} md={4}>
                <Typography variant="overline" gutterBottom>
                  Phone #
                </Typography>
                <Typography variant="body1" gutterBottom>
                  +{data?.phone_num}
                </Typography>
              </Grid>
              <Grid item sm={12} md={4}>
                <Typography variant="overline" gutterBottom>
                  Session Time
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {moment(data?.selected_time, "DD-MM-YYYY HH:mm").subtract(1, 'months').format("DD MMMM YYYY HH:mm")}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="overline" gutterBottom>
                  Current Issue
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.current_issue}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="overline" gutterBottom>
                  Things Has Been Done
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.done_things}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="overline" gutterBottom>
                  Expectation
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.expectation}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <Typography variant="overline" gutterBottom>
                  Checked-in
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data?.checked_in? "Yes": "No"}
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <Typography variant="overline" gutterBottom>
                  Registered At
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {moment(data?.created_at, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY HH:mm")}
                </Typography>
              </Grid>
            </Grid>
          </Card>

        </Container>
      </Modal.Body>
    </Modal>
  )
}