import {
    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { Form, Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";

import logo from "../../assets/qrlogo.jpg"

import moment from "moment"
import Lottie from "lottie-react";
import LottiePrinterAnimation from "../../assets/lottie_printer_1.json"
import LottieDoneAnimation from "../../assets/lottie_check.json"
  

  
  export default function PrintingModal({ onClose, lottieLoading, scannedUser}) {
    const handleClose = (isRefresh = true) => {
        onClose(isRefresh)

      }

    return (
      <Modal
        contentClassName={"printing-modal"}
        className='custom-modal' show={!!scannedUser} backdrop="static" keyboard={false}>
        <Modal.Body>
            <div style={{
                display: "flex",
                flexDirection:'column',
                alignItems: "center"}}>

        {
          lottieLoading ?
            <>
              <Lottie
                style={{
                  height: 300,
                  width: 300,
                  margin: "0 auto",
                  alignItems: "center", justifyContent: "center"
                }}
                animationData={LottiePrinterAnimation} autoPlay loop/>
              <p style={{
                textAlign : "center",
                fontSize : 21,
                fontWeight : "bold"
              }}>
                Welcome {scannedUser?.name}!
              </p>
              <p style={{
                textAlign : "center",
                fontSize : 18,
              }}>
                We are sending your data to the printer
              </p>
            </>
            :
            <>
              <Lottie
                style={{
                  height: 300,
                  width: 300,
                  margin: "0 auto",
                  alignItems: "center", justifyContent: "center"
                }}
                animationData={LottieDoneAnimation} autoPlay loop={false} onComplete={() => handleClose()}/>
              <p style={{
                textAlign : "center",
                fontSize : 21,
                fontWeight : "bold"
              }}>
                Success!
              </p>
              <p style={{
                textAlign : "center",
                fontSize : 18,
              }}>
                We are now printing your badge
              </p>
            </>
        }

            </div>
        </Modal.Body>
      </Modal>
  
            
    )
  
    
  }
  