import {
    Button,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, Image, message, Radio, TimePicker } from 'antd';
import { da } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import SessionModel from 'src/api/session';
import dayjs from 'dayjs'
import { Form, Modal } from "react-bootstrap";
import LoadingButton from "../../../components/reusable/LoadingButton";
import Utils from 'src/api/utils';
const SessionModal = (props) => {

    const AVAILABLE_DATE = ['2023-06-22', '2023-06-23']

    const { open, data, onClose, isNewRecord } = props;
    const [id, setId] = useState();
    const [title, setTitle] = useState();
    const [speaker, setSpeaker] = useState();
    const [startHour, setStartHour] = useState();
    const [endHour, setEndHour] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [messageApi, contextHolder] = message.useMessage();

    const clearData = () => {
        setId()
        setTitle()
        setSpeaker()
        setStartDate()
        setEndDate()
        setStartHour()
        setEndHour()
        setImageUrl()
    }

    const handleClose = (isRefresh = true) => {
        clearData()
        onClose(isRefresh)
    }

    useEffect(() => {
        if (open) {
            setId(data?.id)
            setTitle(data?.title)
            setSpeaker(data?.speaker)
            setStartDate(dayjs(data?.start_time).format('YYYY-MM-DD'))
            setEndDate(dayjs(data?.end_time).format('YYYY-MM-DD'))
            setStartHour(dayjs(data?.start_time))
            setEndHour(dayjs(data?.end_time))
            setImageUrl(data?.image_url)
        } else {
            clearData()
        }
    }, [data])

    // const dummyData = () => {
    //   setName('Robby Haryanto')
    //   setEmail('rhrharyanto55@gmail.com')
    //   setGender("MALE")
    //   setAffiliate('Komune')
    //   setDomicile('Tangerang')
    //   setPhone("62895348736962")
    //   setPassword("123")
    //   setConfirmPassword("123")
    //   setAge(12)

    // }


    const submit = async () => {

        if (!title) {
            message.error('Field Title is required')
            return
        }

        if (!speaker) {
            message.error('Field Speaker is required')
            return
        }

        if (!startDate || !startHour) {
            message.error('Field Start Time is required')
            return
        }

        if (!endDate || !endHour) {
            message.error('Field End Time is required')
            return
        }

        let formattedStartTime = dayjs(startDate).format('YYYY-MM-DD') + ' ' + dayjs(startHour).format('HH:mm')
        let formattedEndTime = dayjs(endDate).format('YYYY-MM-DD') + ' ' + dayjs(endHour).format('HH:mm')

        // console.log('formattedStartTime', formattedStartTime)
        // console.log('formattedEndTime', formattedEndTime)
        // return
        if (moment(formattedStartTime).diff(moment(formattedEndTime).toDate()) > 0) {
            message.error('Start time cannot higher than End Time')
            return
        }


        let body = {
            title: title,
            speaker: speaker,
            start_time: formattedStartTime,
            end_time: formattedEndTime,
            image_url: imageUrl ? imageUrl : null,
        }
        console.log('isi body', body)
        try {
            let res
            if (!data || data.length <= 0) {
                res = await SessionModel.create(body);
                console.log('isi res', res)
                if (res.id) {
                    message.success('Successfully add Session')
                } else {
                    message.error('Failed add Session')
                }
                handleClose(true);
                return;
            }
            res = await SessionModel.edit(id, body);
            console.log(res)
            if (res.success) {
                message.success('Successfully update Session')
            } else {
                message.error('Failed update Session')
            }
            handleClose(true);
            return;
        } catch (e) {
            message.error(e.error_message ? e.error_message : 'Failed update Session')
            return
        }
    }



    const uploadImage = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const files = e.target.files[0];
        if (files.size > 2 * 1024 * 1024) {
            message.error('The file is too big (max 2 mb)')
            return;
        }
        let body = new FormData();
        body.append("upload", files, files.name);
        for (var pair of body.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        let res = await Utils.uploadImage(body);
        setImageUrl(res.location)
    }

    return (

        <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{!isNewRecord ? 'Edit' : 'Add'} Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="session-form">
                    {contextHolder}
                    <Form.Group autoComplete="title" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Title</Form.Label>
                        <Form.Control
                            autoComplete='off'
                            onChange={(e) => setTitle(e.target.value)}
                            type="text"
                            placeholder="Please input Title"
                            value={title}
                        />
                    </Form.Group>

                    <Form.Group autoComplete="speaker" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Speaker</Form.Label>
                        <Form.Control
                            autoComplete='off'
                            onChange={(e) => setSpeaker(e.target.value)}
                            type="text"
                            placeholder="Please input Speaker"
                            value={speaker}
                        />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3 d-block" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Start Time</Form.Label>
                        <div className='d-flex'>
                            <div className='mr-1' style={{ width: '70%' }}>   <DatePicker placeholder='Select Start Date' disabledDate={(current) => {
                                return current && !AVAILABLE_DATE.includes(dayjs(current).format('YYYY-MM-DD'));
                            }} format={'DD/MM/YYYY'} style={{ width: '100%' }} value={startDate ? dayjs(startDate) : startDate}
                                onChange={(date) => setStartDate(date)} />
                            </div>
                            <div style={{ width: '30%', marginLeft: 10 }}>
                                <TimePicker value={startHour ? dayjs(startHour) : startHour} onChange={(time) => setStartHour(time)} format={'HH:mm'} />
                            </div>
                        </div>

                    </Form.Group>

                    <Form.Group autoComplete="new-password" className="mb-3 d-block" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>End Time</Form.Label>
                        <div className='d-flex'>
                            <div className='mr-1' style={{ width: '70%' }}>   <DatePicker placeholder='Select End Date' disabledDate={(current) => {
                                return current && !AVAILABLE_DATE.includes(dayjs(current).format('YYYY-MM-DD'));
                            }} format={'DD/MM/YYYY'} style={{ width: '100%' }} value={endDate ? dayjs(endDate) : endDate}
                                onChange={(date) => setEndDate(date)} />
                            </div>
                            <div style={{ width: '30%', marginLeft: 10 }}>
                                <TimePicker value={endHour ? dayjs(endHour) : endHour} onChange={(time) => setEndHour(time)} format={'HH:mm'} />
                            </div>
                        </div>

                    </Form.Group>

                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Image URL</Form.Label>
                        <Form.Control
                            onChange={(e) => uploadImage(e)}
                            type="file"
                            placeholder="Please enter image url"
                            accept='image/*'
                        />
                        <div className='mb-1'>
                            {imageUrl && <Image preview={false} className='mt-3 mb-3' height={100} width={150} src={imageUrl}></Image>}
                        </div>
                        <Form.Label style={{ fontSize: "0.7em", fontWeight: "lighter" }}>Recommended size: 1200x800 px</Form.Label>


                    </Form.Group>
                    <div className={"d-flex flex-row justify-content-end"}>
                        <Button className='mr-3' size="sm" onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>
                        <LoadingButton className='ml-3' size="sm" onClick={async () => { await submit() }} variant="primary">
                            {isNewRecord ? 'Create' : 'Save'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

    )
}

export default SessionModal;