import ApiRequest from "../utils/apiRequest"

export default class Session {
    static getBookmarkList = async (id) => {
        return await ApiRequest.set("v1/session/bookmark/" + id, "GET")
    }
    static getAll = async () => {
        return await ApiRequest.set("v1/sessions", "GET")
    }
    static getAllWithUserData = async () => {
        return await ApiRequest.set("v1/sessions_with_user_data", "GET")
    }
    static getById = async (id) => {
        return await ApiRequest.set("v1/session/"+id, 'GET')
    }
    static create = async (body) => {
        return await ApiRequest.set("v1/session", "POST", body)
    }

    static edit = async (id, body) => {
        return await ApiRequest.set("v1/session/" + id, "PUT", body)
    }

    static delete = async (id) => {
        return await ApiRequest.set("v1/session/" + id, "DELETE")
    }
}