import { Button, Input } from "@mui/material";
import { message } from "antd";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import LoadingButton from "src/components/reusable/LoadingButton";
import Papa from "papaparse";

export default function UserUploadModal({ open, allowedType, close, submit, dataHandler }) {
    const [dataCsv, setDataCsv] = useState()
    const onDrop = (e) => {

        const file = e.target.files[0]
        console.log(file)
        // console.log(image)
        // console.log(image[0].type)

        if (allowedType) {
            if (!allowedType.includes(file)) {
                message.error("Please upload file with the type  : " + allowedType)
                return
            }
        }

        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = async (e) => {
            let stringCSV = e.target.result;
            let parseResult = Papa.parse(stringCSV, {
                delimiter: ";",
                header: true,
                skipEmptyLines: true,
            });
            console.log("PPPR", parseResult);

            let unFormattedData = parseResult.data;
            console.log('uploading Data', unFormattedData)

            setDataCsv(unFormattedData)

        }

        // props.onDrop(image)

    }
    const handleClose = () => {
        close()
    }
    const submitUpload = () => {
        submit(dataCsv)
    }
    return (
        <>
            <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Upload User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Input
                            type="file"
                            onChange={onDrop}>

                        </Input>

                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className={"d-flex flex-row justify-content-end"}>
                                <Button className='mr-3' size="sm" onClick={handleClose} variant="outlined">
                                    Cancel
                                </Button>
                                <LoadingButton className='ml-3' size="sm" onClick={async () => { await submitUpload() }} variant="primary">
                                    Process Upload
                                </LoadingButton>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}