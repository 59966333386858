import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs'
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Popover,
    Container,
    Typography,
    IconButton,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
// mock
import palette from 'src/utils/palette';
import SessionModal from 'src/sections/@dashboard/session/SessionModal';
import SessionModel from 'src/api/session';
import CustomTable from "../components/reusable/CustomTable"
import { Image, message, Tooltip } from 'antd';
import SessionBookmarkModal from 'src/sections/@dashboard/session/SessionBookmarkModal';
import { Link, useParams } from 'react-router-dom';
import SessionUserModel from 'src/api/sessionuser';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import UserSessionDetailModal from 'src/components/custom/UserSessionDetailModal';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function SessionUserPage() {
    const { id } = useParams();
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setData(row)
    };

    const SessionIds = [
        "Business Process",
        "Technology",
        "Organization",
    ]

    const handleCloseMenu = () => {
        setOpen(null);
        setData(null);
    };

    const deleteEntry = async () => {
        try {
            let res = await SessionModel.delete(data.id)
            console.log(res)
            if (res.success) {
                message.success("Successfully delete session")
                fetchSessionUser()
                handleCloseMenu()
                return
            } else {
                message.error("Failed to delete session")
            }
            throw (res)
        } catch (error) {
            console.log(error)
            message.error('There was error from server')
            return
        }
    }

    const fetchSessionUser = async () => {
        try {
            let res = await SessionUserModel.getUsersBySessionId(id);
            let formatted = res.map((row, index) => (
                {
                    ...row,
                    selected_time: moment(row.selected_time).format("DD-MM-YYYY HH:mm"),
                    phone_num: row.phone_num ? "+" + row.phone_num : "",
                    created_at: moment(row.created_at).format("DD-MM-YYYY HH:mm"),
                    session_checked_in: row.session_checked_in ? "Yes" : "No",
                    index : index + 1
                }
            ))
            // console.log("RESU", res)
            setUsers(formatted)
        } catch (error) {
            console.log(error)
        }
    }

    const limitString = (text, limit) => {
        let substrings = text.split(/\s+/)
        return <>{substrings.slice(0, limit).join(" ")}{substrings.length > 5 ? "..." : ""}
        </>
    }

    useEffect(() => {
        fetchSessionUser();
    }, [])

    const columns = [
        {
            id: 'index', label: 'No.', filter: true,
        },
        {
            id: 'full_name', label: 'Name', filter: true,
        },
        {
            id: 'company', label: 'Company', filter: true,
        },
        {
            id: 'job_title', label: 'Job Title', filter: true,
        },
        {
            id: 'industry', label: 'Industry', filter: true,
        },
        {
            id: 'selected_time', label: 'Session Time', filter: true, render: row => {
                return moment(row.selected_time, "DD-MM-YYYY HH:mm").subtract(1, 'months').format("DD-MMMM-YYYY HH:mm")
            }
        },
        {
            id: 'phone_num', label: 'Phone #', filter: true, render: row => {
                return <>+{row.phone_num}
                </>
            }
        },
        {
            id: 'current_issue', label: 'Current Issue', filter: true, render: row => {
                return limitString(row.current_issue, 5)
            }
        },
        {
            id: 'done_things', label: 'Things Has Been Done', filter: true, render: row => {
                return limitString(row.done_things, 5)
            }
        },
        {
            id: 'expectation', label: 'Expectation', filter: true, render: row => {
                return limitString(row.expectation, 5)
            }
        },
        {
            id: 'session_checked_in', label: 'Checked-in', filter: false, render: row => {
                return <>
                    {row.session_checked_in ? "Yes" : "No"}
                </>
            }
        },
        {
            id: 'created_at', label: 'Registered At', filter: false, render: row => {
                return <>
                    {moment(row.created_at, "DD-MM-YYYY HH:mm").format("DD MMMM YYYY HH:mm")}
                </>
            }
        },
        {
            id: 'actions', label: '', filter: false, render: row => {
                return (
                    <Tooltip title="See details">
                        <IconButton size="large" color="inherit" onClick={() => setUser(row)}>
                            <Iconify icon={'bx:message-alt-detail'} sx={{ color: "black" }} />
                        </IconButton>
                    </Tooltip>
                )
            }
        },
    ];

    const csvHeaders = [
        {
            key: 'id', label: 'ID',
        },
        {
            key: 'full_name', label: 'Name',
        },
        {
            key: 'company', label: 'Company',
        },
        {
            key: 'job_title', label: 'Job Title',
        },
        {
            key: 'industry', label: 'Industry',
        },
        {
            key: 'selected_time', label: 'Session Time',
        },
        {
            key: 'phone_num', label: 'Phone #',
        },
        {
            key: 'current_issue', label: 'Current Issue',
        },
        {
            key: 'done_things', label: 'Things Has Been Done',
        },
        {
            key: 'expectation', label: 'Expectation',
        },
        {
            key: 'session_checked_in', label: 'Checked-in',
        },
        {
            key: 'created_at', label: 'Registered At',
        },
    ]

    return (
        <>
            <Helmet>
                <title> Users in {SessionIds[id - 1] || "Session"} | Fordigi</title>
            </Helmet>

            <Container style={{ maxWidth: '90%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Coaching Clinic Registration for : {SessionIds[id - 1]}
                    </Typography>
                    <Button style={{ backgroundColor: palette.ColorB, color: 'white' }} variant="contained" startIcon={<Iconify icon={"bi:filetype-csv"} />}>
                        <CSVLink
                            data={users}
                            headers={csvHeaders}
                            filename={SessionIds[id - 1] + ".csv"}
                            target="_blank"
                            style={{ color: 'white', textDecoration: 'none' }}
                        >
                            Download as CSV
                        </CSVLink>
                    </Button>
                </Stack>

                <Card>
                    <CustomTable
                        data={users}
                        columns={columns}
                        searchText="users"
                    />
                </Card>
                <UserSessionDetailModal data={user} onHide={() => setUser(null)} />
            </Container>
        </>
    );
}
