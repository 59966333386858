import {
    Button,
} from '@mui/material';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { Form, Modal } from "react-bootstrap";
import LoadingButton from "../../../components/reusable/LoadingButton";
import Utils from 'src/api/utils';
import ScanLocationModel from 'src/api/scan-location';
const ScanLocationModal = (props) => {
    const { open, data, onClose, isNewRecord } = props;
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [uuid, setUUID] = useState();

    const clearData = () => {
        setId()
        setName()
        setUUID()
    }

    const handleClose = (isRefresh = true) => {
        clearData()
        onClose(isRefresh)
    }

    useEffect(() => {
        if (open) {
            setId(data?.id)
            setName(data?.name)
            setUUID(data?.uuid)
        } else {
            clearData()
        }
    }, [data])

    const submit = async () => {

        if (!name) {
            message.error('Field Name is required')
            return
        }

        if (!uuid) {
            message.error('Field UUID is required')
            return
        }

        let body = {
            name: name,
            uuid:uuid
        }
        try {
            let res
            if (!data || data.length <= 0) {
                res = await ScanLocationModel.create(body);
                if (res.uuid) {
                    message.success('Successfully add Scan Location')
                } else {
                    message.error('Failed update Scan Location')
                }
                handleClose(true);
                return;
            }
            res = await ScanLocationModel.edit(id, body);
            console.log(res)
            if (res.success) {
                message.success('Successfully update Scan Location')
            } else {
                message.error('Failed update Scan Location')
            }
            handleClose(true);
            return;
        } catch (e) {
            message.error(e.error_message ? e.error_message : 'Failed update Scan Location')
            return
        }
    }



    return (

        <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{!isNewRecord ? 'Edit' : 'Add'} Scan Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="scan-location-form">
                    <Form.Group autoComplete="title" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Name</Form.Label>
                        <Form.Control
                            autoComplete='off'
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Please input Name"
                            value={name}
                        />
                    </Form.Group>
                    <Form.Group autoComplete="title" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>UUID</Form.Label>
                        <Form.Control
                            autoComplete='off'
                            onChange={(e) => setUUID(e.target.value)}
                            type="text"
                            placeholder="Please input UUID"
                            value={uuid}
                        />
                    </Form.Group>
                    <div className={"d-flex flex-row justify-content-end"}>
                        <Button className='mr-3' size="sm" onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>
                        <LoadingButton className='ml-3' size="sm" onClick={async () => { await submit() }} variant="primary">
                            {isNewRecord ? 'Create' : 'Save'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

    )
}

export default ScanLocationModal;