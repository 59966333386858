import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs'
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  Switch,
  TableContainer,
  TablePagination,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { CSVLink } from "react-csv";
import userTemplate from '../assets/sampleUser.json'
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import moment from "moment";
import USERLIST from '../_mock/user';
import palette from 'src/utils/palette';
import UserModal from 'src/sections/@dashboard/user/UserModal';
import Admin from 'src/api/admin';
import User from 'src/api/user';
import ScanLocation from 'src/api/scan-location';
import CustomTable from "../components/reusable/CustomTable"
import UserTagModalV3 from 'src/components/custom/UserTagModalV3';
import UserTagModal from "../components/custom/UserTagModal";
import { message, Tooltip } from 'antd';
import LoadingButton from 'src/components/reusable/LoadingButton';
import UserUploadModal from 'src/sections/@dashboard/user/UserUploadModal';
// ----------------------------------------------------------------------
import Papa from "papaparse";
import UserModalLite from 'src/sections/@dashboard/user/UserModalLite';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState([]);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("all");

  const [isNewRecord, setIsNewRecord] = useState(true)
  const [formMode, setFormMode] = useState(null);

  const [qrData, setQRData] = useState(null)
  const [qrDataForThermal, setQRDataForThermal] = useState(null)
  const [liteMode, setLiteMode] = useState(true)


  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setData(row)
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setData(null);
  };

  const deleteEntry = async () => {
    try {
      let res = await User.delete(data.id)
      console.log(res)
      if (res.success) {
        message.success("Successfully delete user")
        fetchUsers()
        handleCloseMenu()
        return
      } else {
        message.error("Failed to delete user")
      }
      throw (res)
    } catch (error) {
      console.log(error)
      message.error('There was error from server')
      return
    }
  }

  const fetchUsers = async () => {
    try {
      let res = await User.getAllNoRestrictions();
      console.log(res)
      let formattedRes = res.map((obj, key) => {
        return {
          ...obj,
          no: key + 1,
          uppercaseName: obj?.name?.toUpperCase()
        }
      })
      setUsers(formattedRes)
      setFilteredUsers(formattedRes)
    } catch (error) {
      console.log(error)
    }
  }

  const filterUsers = (filter) => {
    switch (filter) {
      case 'all': setFilteredUsers(users.map((obj, key) => {
        return {
          ...obj, no: (key + 1)
        }
      }))
        return;
      case 'visitor': setFilteredUsers(users.filter(el => el.role == 'VISITOR' || el.role == 'ASSOCIATE').map((obj, key) => {
        return {
          ...obj, no: (key + 1)
        }
      }))
        return;
      case 'checked-in': setFilteredUsers(users.filter(el => !!el.check_in_time && el.role == 'VISITOR').map((obj, key) => {
        return {
          ...obj, no: (key + 1)
        }
      }))
        return;
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [])

  useEffect(() => {
    filterUsers(filter)
  }, [filter])

  const columns = [
    {
      id: 'no', label: 'No.', filter: true,
    },
    {
      id: 'uppercaseName', label: 'Full Name', filter: true,
      render: row => {
        return <>
          <b>{row.name}</b>
        </>
      }
    },
    {
      id: 'email', label: 'Email', filter: true,
    },
    {
      id: 'affiliate', label: 'Institution/Company', filter: true,
    },
    {
      id: 'job_title', label: 'Job Title', filter: true,
    },
    {
      id: 'sector', label: 'Industry', filter: true,
    },
    {
      id: 'phone_num', label: 'Phone Number', filter: true, render: row => {
        return <>
          {row?.phone_num ? `+62${row.phone_num}` : '-'}
        </>
      }
    },
    {
      id: 'gender', label: 'Gender', filter: true,
    },
    {
      id: 'role', label: 'Role', filter: true,
    },
    {
      id: 'age', label: 'Age', filter: true, render: (row) => {
        const formatted = User.getListAge.filter((v) => {
          return v.value == row.age
        })

        return <>
          {formatted?.length > 0 ? formatted[0].label : '-'}
        </>
      }
    },
    {
      id: 'domicile', label: 'Domicile', filter: true,
    },
    {
      id: 'registered_at', label: 'Registered At', filter: true, render: row => {
        return <>
          {moment(row.created_at).format("DD//MM/YY HH:mm")}
        </>
      }
    },
    {
      id: 'checked_in', label: 'Checked In', filter: true, render: row => {
        return <>
          {row.check_in_time ? 'Yes' : 'No'}
        </>
      }
    },
    {
      id: 'actions', label: "", render: (row, key) => {
        console.log('isi row', row)
        return <>
          <Tooltip title="Print Nametag">
            <IconButton
              onClick={() => {
                setQRData({
                  name: row.name,
                  /*  title: "PESERTA", */
                  code: row.uuid,
                  uuid: row.uuid,
                  jobTitle: row?.job_title,
                  companyName: row?.affiliate,
                  showStatus: 1
                  /* role: row.role */
                })
              }}
              size="large" color="inherit">
              <Iconify icon={'eva:printer-fill'} sx={{}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Print Thermal">
            <IconButton
              onClick={() => {
                setQRDataForThermal({
                  name: row.name,
                  title: "PESERTA",
                  code: row.uuid
                })
              }}
              size="large" color="inherit">
              <Iconify icon={'openmoji:barcode'} sx={{}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton size="large" color="inherit" onClick={(event) => {
              setData(row);
              setOpenModal(true);
              setFormMode('edit');
              setIsNewRecord(false);
            }}>
              <Iconify icon={'eva:edit-fill'} sx={{}} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </>
      }
    },
  ];

  const dataHandler = async (res) => {
    let formattedData = [];
    let reader = new FileReader();
    reader.readAsText(res[0], "UTF-8");
    reader.onload = async (e) => {
      let stringCSV = e.target.result;
      let parseResult = Papa.parse(stringCSV, {
        delimiter: ",",
        header: true,
        skipEmptyLines: true,
      });
      console.log("PPPR", parseResult);

      let unFormattedData = parseResult.data;
      console.log('uploading Data', unFormattedData)
    }
  }

  const submit = async (uploadResult) => {
    try {
      // console.log("PROCESSED", uploadResult)
      let formatted = uploadResult.map(o => {
        return {
          ...o,
          age: parseInt(o.age)
        }
      })
      console.log("PROCESSED", formatted)

      let res = await User.createBatch(formatted)
      console.log(res)
      alert("Success, see console for more information")
    } catch (e) {
      alert("Error, see console for more information")
      console.log(e)
    }

  }

  return (
    <>
      <Helmet>
        <title> User | Fordigi</title>
      </Helmet>

      <Container maxWidth={'xl'} disableGutters>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Registered User
          </Typography>
          <div>
            <Button style={{ backgroundColor: palette.ColorB }} variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
                setIsNewRecord(true)
                setOpenModal(true)
              }}>
              New User
            </Button>

            <Button onClick={() => {
              setOpenUpload(true)
            }} startIcon={<Iconify icon="ic:baseline-upload" />}
              style={{ backgroundColor: palette.ColorD, marginLeft: 10 }} variant="container">
              Upload Batch
            </Button>

            <Button style={{ backgroundColor: palette.ColorA, marginLeft: 10 }} variant="contained"
              startIcon={<Iconify icon="ic:baseline-download" />}>
              <CSVLink
                data={userTemplate}
                target="_blank"
                filename="user_batch_template.csv"
                style={{ color: "white", textDecoration: 'none' }}
                separator=';'
              >
                Download CSV Example
              </CSVLink>
            </Button>

            <Button style={{ backgroundColor: palette.ColorF, marginLeft: 10 }} variant="contained"
              startIcon={<Iconify icon="ic:baseline-download" />}>
              <CSVLink
                data={users?.map((v, key) => {
                  return {
                    "#": key += 1,
                    uuid: v.uuid,
                    "Full Name": v.name,
                    "Email": v.email,
                    "Institution/Company": v.affiliate,
                    "Job Title": v.job_title,
                    "Industry": v.sector,
                    "Phone Number": v.phone_num,
                    "Gender": v.gender,
                    "Age": v.age,
                    "Domicile": v.domicile,
                  }
                })}
                target="_blank"
                filename={`user_export_${dayjs().format('DD.MM.YYYY HH:mm')}.csv`}
                style={{ color: "white", textDecoration: 'none' }}
              >
                Download All
              </CSVLink>
            </Button>
            {/*<Button style={{ backgroundColor: palette.ColorA, marginLeft: 10 }} variant="contained" startIcon={<Iconify icon="ic:baseline-download" />}>*/}
            {/*  <CSVLink*/}
            {/*    data={userTemplate}*/}
            {/*    target="_blank"*/}
            {/*    filename="user_batch_template.csv"*/}
            {/*    style={{ color: "white", textDecoration: 'none' }}*/}
            {/*  >*/}
            {/*    Download CSV Example*/}
            {/*  </CSVLink>*/}
            {/*</Button>*/}

          </div>

        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
          <FormGroup row>
            <FormControlLabel control={<Switch checked={filter == "all"} onChange={() => setFilter("all")} />} label={"Show all"} />
            <FormControlLabel control={<Switch checked={filter == "visitor"} onChange={() => setFilter("visitor")} />} label={"Show Visitor Only"} />
            <FormControlLabel control={<Switch checked={filter == "checked-in"} onChange={() => setFilter("checked-in")} />} label={"Show Checked In Only"} />
            {/*<FormControlLabel control={<Switch checked={liteMode} onChange={() => setLiteMode(!liteMode)} />} label={"Lite Mode"} />*/}

          </FormGroup>
        </Stack>
        <Card>
          <CustomTable
            data={filteredUsers}
            columns={columns}
            searchText="users"
          />
        </Card>

      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={() => setOpenModal(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
        <Typography>Are you sure you want to delete this entry?</Typography>
        <div className='d-flex justify-content-between'>
          <Button onClick={handleCloseMenu}>No</Button>
          <Button onClick={deleteEntry}>Yes</Button>
        </div>
      </Popover>

      {
        liteMode ? <UserModalLite
          mode={formMode}
          open={openModal}
          isNewRecord={isNewRecord}
          onClose={(isRefresh) => {
            setOpenModal(false);
            setData(null);
            setIsNewRecord(true)
            if (isRefresh) fetchUsers();
          }}
          data={data}
        /> : <UserModal
          mode={formMode}
          open={openModal}
          isNewRecord={isNewRecord}
          onClose={(isRefresh) => {
            setOpenModal(false);
            setData(null);
            setIsNewRecord(true)
            if (isRefresh) fetchUsers();
          }}
          data={data}
        />
      }

      <UserTagModal
        data={qrData}
        onClose={() => {
          setQRData(null)
        }}
      />
      <UserUploadModal
        submit={submit}
        open={openUpload}
        close={() => setOpenUpload(false)}
        dataHandler={dataHandler}
      />
    </>
  );
}
