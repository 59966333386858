import {

    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, message, Radio } from 'antd';
import { da } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import User from 'src/api/user';
import dayjs from 'dayjs'
import { Form, InputGroup, Modal } from "react-bootstrap";
import LoadingButton from "../../../components/reusable/LoadingButton";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const UserModalLite = (props) => {
    const { open, data, onClose, mode, isNewRecord } = props;
    const [id, setId] = useState();
    const [avatarUrl, setAvatarUrl] = useState();
    const [dob, setDob] = useState();
    const [gender, setGender] = useState();
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [password, setPassword] = useState();
    const [age, setAge] = useState();
    const [affiliate, setAffiliate] = useState();
    const [sector, setSector] = useState();
    const [domicile, setDomicile] = useState();
    const [jobTitle, setJobTitle] = useState();
    const [role, setRole] = useState('VISITOR');
    const [confirmPassword, setConfirmPassword] = useState();

    const clearData = () => {
        setAge()
        setGender()
        setName()
        setPhone()
        setPassword()
        setEmail()
        setSector()
        setConfirmPassword()
        setDomicile()
        setRole()
        setJobTitle()
        setAffiliate()
    }

    const handleClose = (isRefresh = true) => {
        clearData()
        onClose(isRefresh)
    }



    useEffect(() => {
        if (open) {
            setId(data?.id);
            setName(data?.name);
            setGender(data?.gender);
            setEmail(data?.email);
            setSector(data?.sector);
            setPhone(formattedPhoneNumber(data?.phone_num));
            setAffiliate(data?.affiliate);
            setJobTitle(data?.job_title);
            setDomicile(data?.domicile);
            setRole(data?.role);
            setAge(data?.age);
        } else {
            clearData()
        }
    }, [data])

    const formattedPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) {
            return null
        }
        let phoneFormat = phoneNumber;
        const code = phoneNumber.slice(0, 2)
        if (code == 62) {
            phoneFormat = phoneNumber.slice(2, phoneNumber.length)
        }
        return phoneFormat
    }

    const submit = async () => {
        console.log('name field', name)
        // if (!name) {
        //   message.error('Field Full Name is required')
        //   return
        // }

        // if (!email) {
        //   message.error('Field Email is required')
        //   return
        // }


        // if (!jobTitle) {
        //   message.error('Field Job Title is required')
        //   return
        // }

        // if (!affiliate) {
        //   message.error('Field Company/Industry is required')
        //   return
        // }

        // if (!phone) {
        //   message.error('Field Phone Number is required')
        //   return
        // }

        // if (!gender) {
        //   message.error('Field Gender is required')
        //   return
        // }

        // if (!age) {
        //   message.error('Field Age is required')
        //   return
        // }


        // if (!domicile) {
        //   message.error('Field Domicile is required')
        //   return
        // }


        // if (password !== confirmPassword) {
        //   message.error('Password and Confirm Password not match')
        //   return
        // }


        let body = {
            name: name,
            email: `placeholder+${Math.floor(Math.random() * 1000000)}@gmail.com`,
            role: role,
            affiliate: affiliate,
            job_title: jobTitle,
        }
        console.log('body', body)
        try {
            let res
            if (!data || data.length <= 0) {
                res = await User.create(body);
                if (res.uuid) {
                    message.success('Successfully add user')
                } else {
                    message.error('Failed add user')
                }
                handleClose(true);
                return;
            }
            res = await User.edit(id, body);
            console.log(res)
            if (res.success) {
                message.success('Successfully update user')
            } else {
                message.error('Failed update user')
            }
            handleClose(true);
            return;
        } catch (e) {
            message.error(e.error_message ? e.error_message : 'Failed update user')
            return
        }
    }

    return (

        <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{!isNewRecord ? 'Edit' : 'Add'} User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="users-form">

                    <Form.Group autoComplete="name" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Name</Form.Label>
                        <Form.Control
                            autoComplete='off'
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Please input name"
                            value={name}
                        />
                    </Form.Group>

                    <Form.Group autoComplete="job-title" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Job Title</Form.Label>
                        <Form.Control
                            autoComplete='off'
                            onChange={(e) => setJobTitle(e.target.value)}
                            type="text"
                            placeholder="Please input Job Title"
                            value={jobTitle}
                        />
                    </Form.Group>
                    <Form.Group autoComplete="affiliate" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Company/Industry</Form.Label>
                        <Form.Control
                            autoComplete='off'
                            onChange={(e) => setAffiliate(e.target.value)}
                            type="text"
                            placeholder="Please input Company/Industry"
                            value={affiliate}
                        />
                    </Form.Group>

                    <Form.Group autoComplete="role" className="mb-3">
                        <Form.Label style={{ fontSize: "0.8em" }}>Role</Form.Label>
                        <Form.Select defaultValue={'VISITOR'} value={role} onChange={(e) => {
                            setRole(e.target.value)
                        }} aria-label="Default select example">
                            <option value={null}>Please select Role</option>
                            {
                                User.getListRole?.map((v) => {
                                    return (
                                        <>
                                            <option value={v.value}>{v.label}</option>
                                        </>
                                    )
                                })
                            }

                        </Form.Select>
                    </Form.Group>


                    <div className={"d-flex flex-row justify-content-end"}>
                        <Button className='mr-3' size="sm" onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>
                        <LoadingButton className='ml-3' size="sm" onClick={async () => { await submit() }} variant="primary">
                            {isNewRecord ? 'Create' : 'Save'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

    )
}

export default UserModalLite;