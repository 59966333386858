import {

  Button,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Form, Modal } from "react-bootstrap";
import nameTagTemplate from "../../assets/nametagtemplate.png"
import { useRef, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import logo from "../../assets/qrlogo.jpg"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment"
import intLogo from '../../assets/int-logo-black.png'
import intHeaderLogo from '../../assets/logo_white2.png'
import pdfMake, { tableLayouts } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import printJS from "print-js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function UserTagModalV3({ data, onClose }) {

  let formattedData = data ? data : {}
  let { name, title, code } = formattedData

  const [nameTagWidth, setNameTagWidth] = useState(0)
  const [isPrint, setIsPrint] = useState(false)

  const printContainerRef = useRef(null)

  const handleClose = (isRefresh = true) => {
    onClose(isRefresh)
  }

  const print = () => {

  }

  const generatePDF = async (isPrintMode) => {
    setIsPrint(true)
    const canvas = await html2canvas(printContainerRef.current, {
      scale: 1,
      quality: 4,
      dpi: 320
    });

    let imgWidth = 216;
    const image = canvas.toDataURL("image/jpeg");

    const pdf = new jsPDF('p', 'mm', [78, 60]);
    await pdf.addImage(image, 'JPEG', 0, -10, 60, 78);
    // pdf.output('dataurlnewwindow');

    if (isPrintMode) {
      pdf.autoPrint({ variant: 'non-conform' });
      let output = pdf.output(`bloburl`)

      printJS(output)
      // console.log("output", output)
      // window.open(output)

    } else {
      await pdf.save(`${name}_NC${moment().format("DDMMYYYYHH:mm")}.pdf`);
    }
    setIsPrint(false)
  }

  const generatePDFV2 = async () => {

    const canvas = await html2canvas(printContainerRef.current, {
      scale: 1.1,
      quality: 4,
      dpi: 320
    });

    const image = canvas.toDataURL("image/jpeg");
  }

  return (
    <Modal
      contentClassName={"name-tag-modal"}
      className='custom-modal' show={!!data} backdrop="static" keyboard={false}>
      <Modal.Body>

        <div ref={printContainerRef}>
          <div
            className={"d-flex flex-column align-items-center justify-content-center"}
            ref={(ref) => {
              if (ref) {
                console.log('ref', ref.clientWidth)
                setNameTagWidth(ref.clientWidth)
              }
            }}
            style={{
              // background: `url(${nameTagTemplate})`,
              backgroundSize: "contain",
              backgroundRepeatX: "no-repeat",
              backgroundRepeatY: "no-repeat",
              width: "100%",
              height: 1.4095 * nameTagWidth,
            }}
          >
            <img src={intHeaderLogo}></img>
            <div
              className={"d-flex flex-column align-items-center justify-content-center"}
              style={{
                background: "white",
                width: 0.6 * nameTagWidth,
                height: 0.7 * nameTagWidth,
                borderRadius: 0.02 * nameTagWidth
              }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={code}
                viewBox={`0 0 256 256`}
                ecLevel='H'
              // logoImage={logo}
              // logoWidth={60}
              />
              {/*<div style={{*/}
              {/*  width : "100%",*/}
              {/*  height : 300,*/}
              {/*  background : `url("https://wellsource.sgp1.digitaloceanspaces.com/intellivent-register/qr/qr_Damocles_1683262576704.png")`*/}
              {/*}}>*/}

              {/*</div>*/}
              {/*<img*/}
              {/*  style={{*/}
              {/*    width: "100%",*/}
              {/*    objectFit: "contain",*/}
              {/*    borderRadius: 0.02 * nameTagWidth*/}
              {/*  }}*/}
              {/*  src={"https://wellsource.sgp1.digitaloceanspaces.com/intellivent-register/qr/qr_Damocles_1683262576704.png"}/>*/}
              <div style={{
                fontSize: nameTagWidth * 0.04,
                fontWeight: 'bolder',
                marginBottom: nameTagWidth > 320 ? '20px' : '0px',
              }}>
                {code}
              </div>
            </div>
            <div className='d-flex w-100'>
              <div style={{
                color: "black",
                fontWeight: "bold",
                fontSize: nameTagWidth * 0.09,
                // marginTop: nameTagWidth * 0.01,
                textTransform: "uppercase",
                marginTop: '10px',
                textAlign: 'center',
                width: '90%',
                marginLeft: '50px'
              }}>
                {name}
              </div>
              <img style={{
                top: '50%', 
                position: 'relative',
                width: '30px',
                height: '30px',
                objectFit:'contain',
                marginRight:'20px'
              }} src={intLogo}></img>
            </div>

            <div style={{
              color: "black",
              fontSize: nameTagWidth * 0.03,
              fontWeight: 'bold',
              // marginTop: nameTagWidth * 0.0001,
              marginTop: nameTagWidth > 320 ? '10px' : '0px',
              textTransform: "uppercase"
            }}>
              {title}
            </div>

          </div>

        </div>


        <div className={"d-flex flex-row justify-content-end mt-2"}>
          <Button className='ml-3 me-2' size="sm" onClick={() => {
            handleClose()
          }} variant="outlined">
            Close
          </Button>
          <Button className='ml-3 me-2' size="sm" onClick={() => {
            generatePDF()
          }} variant="outlined">
            Download
          </Button>
          <Button className='ml-3' size="sm" onClick={() => {
            generatePDF(true)
            // generatePDFV2()
          }} variant="contained">
            Print
          </Button>
        </div>

      </Modal.Body>
    </Modal>

  )
}
