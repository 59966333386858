import {
    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { Form, Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";

import logo from "../../assets/qrlogo.jpg"

import moment from "moment"
import Lottie from "lottie-react";
import LottieErrorAnimation from "../../assets/lottie_error.json"
  

  
  export default function ErrorModal({ onClose, errorMessage}) {
    const handleClose = (isRefresh = true) => {
        onClose(isRefresh)
        
      }

    return (
      <Modal
        contentClassName={"printing-modal"}
        className='custom-modal' show={!!errorMessage} backdrop="static" keyboard={false}>
        <Modal.Body>
            <div style={{
                /* display: "flex", */
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center"}}>


            <Lottie
            style={{
                height: 300,
                width: 300,
                margin: "0 auto",
                alignItems: "center", justifyContent: "center"
            }}
            animationData={LottieErrorAnimation} autoPlay loop/>
            <p style={{
            textAlign : "center",
            fontSize : 21,
            fontWeight : "bold"
            }}>
                {errorMessage}
            </p>



            </div>
        </Modal.Body>
      </Modal>
  
            
    )
  
    
  }
  