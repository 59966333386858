import {

  Button,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, message, Radio } from 'antd';
import { da } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import User from 'src/api/user';
import dayjs from 'dayjs'
import { Form, InputGroup, Modal } from "react-bootstrap";
import LoadingButton from "../../../components/reusable/LoadingButton";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const UserModalAdd = (props) => {
  const { open, data, onClose, mode, isNewRecord } = props;
  const [id, setId] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [dob, setDob] = useState();
  const [gender, setGender] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [age, setAge] = useState();
  const [affiliate, setAffiliate] = useState();
  const [sector, setSector] = useState();
  const [domicile, setDomicile] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [role, setRole] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const clearData = () => {
    setAge()
    setGender()
    setName()
    setPhone()
    setPassword()
    setEmail()
    setSector()
    setConfirmPassword()
    setDomicile()
    setRole()
    setJobTitle()
    setAffiliate()
  }

  const handleClose = (isRefresh = true) => {
    clearData()
    onClose(isRefresh)
  }

  useEffect(() => {
    if (open) {
      if (isNewRecord) {
        setId(data?.id);
        setName(data?.name);
        setGender(data?.gender);
        setEmail(data?.email);
        setSector(data?.sector);
        setPhone(formattedPhoneNumber(data?.phone_num));
        setAffiliate(data?.affiliate);
        setJobTitle(data?.job_title);
        setDomicile(data?.domicile);
        setRole(data?.role);
        setAge(data?.age);
      }

    } else {
      clearData()
    }
  }, [data])

  // const dummyData = () => {
  //   setName('Robby Haryanto')
  //   setEmail('rhrharyanto55@gmail.com')
  //   setGender("MALE")
  //   setAffiliate('Komune')
  //   setDomicile('Tangerang')
  //   setPhone("62895348736962")
  //   setPassword("123")
  //   setConfirmPassword("123")
  //   setAge(12)

  // }

  const formattedPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return null
    }
    let phoneFormat = phoneNumber;
    const code = phoneNumber.slice(0, 2)
    if (code == 62) {
      phoneFormat = phoneNumber.slice(2, phoneNumber.length)
    }
    return phoneFormat
  }

  const submit = async () => {
    console.log('name field', name)
    // if (!name) {
    //   message.error('Field Full Name is required')
    //   return
    // }

    // if (!email) {
    //   message.error('Field Email is required')
    //   return
    // }


    // if (!jobTitle) {
    //   message.error('Field Job Title is required')
    //   return
    // }

    // if (!affiliate) {
    //   message.error('Field Company/Industry is required')
    //   return
    // }

    // if (!phone) {
    //   message.error('Field Phone Number is required')
    //   return
    // }

    // if (!gender) {
    //   message.error('Field Gender is required')
    //   return
    // }

    // if (!age) {
    //   message.error('Field Age is required')
    //   return
    // }


    // if (!domicile) {
    //   message.error('Field Domicile is required')
    //   return
    // }


    // if (password !== confirmPassword) {
    //   message.error('Password and Confirm Password not match')
    //   return
    // }


    let body = {
      name: name,
      gender: gender,
      email: email,
      affiliate: affiliate,
      age: age ? parseInt(age) : null,
      // password: password,
      domicile: domicile,
      job_title: jobTitle,
      sector: sector,
      role: role,
      phone_num: phone ? `${phone}` : phone,
    }

    /* if(phone) {
      body.phone_num = `${phone}`
    } else {
      body.phone_num = phone
    } */

    try {
      let res
      if (!data || data.length <= 0) {
        res = await User.create(body);
        if (res.uuid) {
          message.success('Successfully add user')
        } else {
          message.error('Failed add user')
        }
        handleClose(true);
        return;
      }
      res = await User.edit(id, body);
      console.log(res)
      if (res.success) {
        message.success('Successfully update user')
      } else {
        message.error('Failed update user')
      }
      handleClose(true);
      return;
    } catch (e) {
      message.error(e.error_message ? e.error_message : 'Failed update user')
      return
    }
  }

  return (

    <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{!isNewRecord ? 'Edit' : 'Add'} User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form autoComplete="users-form">

          <Form.Group autoComplete="name" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Name</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Please input name"
              value={name}
            />
          </Form.Group>

          <Form.Group autoComplete="email" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>E-Mail</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Please input email"
              value={email}
            />
          </Form.Group>
          {/* <Form.Group autoComplete="new-password" className="mb-3 d-block" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Date of Birth</Form.Label>
            <DatePicker disabledDate={(current) => {
              return current && current >= dayjs().startOf('day');
            }} format={'DD/MM/YYYY'} style={{ width: '100%' }} value={dob ? dayjs(dob) : dob}
              onChange={(date) => setDob(date)} />
          </Form.Group> */}
          {/* <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Age</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setAge(e.target.value)}
              type="number"
              placeholder="Please input Age"
              value={age}
            />
          </Form.Group> */}
          <Form.Group autoComplete="affiliate" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Company/Industry</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setAffiliate(e.target.value)}
              type="text"
              placeholder="Please input Company/Industry"
              value={affiliate}
            />
          </Form.Group>

          <Form.Group autoComplete="job-title" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Job Title</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setJobTitle(e.target.value)}
              type="text"
              placeholder="Please input Job Title"
              value={jobTitle}
            />
          </Form.Group>

          <Form.Group autoComplete="sector" className="mb-3">
            <Form.Label style={{ fontSize: "0.8em" }}>Industry</Form.Label>
            <Form.Select value={sector} onChange={(e) => {
              setSector(e.target.value)
            }} aria-label="Default select example">
              <option value={null}>Please select Industry</option>
              {
                User.getListIndustry?.map((v) => {
                  return (
                    <>
                      <option value={v.value}>{v.label}</option>
                    </>
                  )
                })
              }

            </Form.Select>
          </Form.Group>

          <Form.Group autoComplete="role" className="mb-3">
            <Form.Label style={{ fontSize: "0.8em" }}>Role</Form.Label>
            <Form.Select value={role} onChange={(e) => {
              setRole(e.target.value)
              console.log(role)
            }} aria-label="Default select example">
              <option value={null}>Please select Role</option>
              {
                User.getListRole?.map((v) => {
                  return (
                    <>
                      <option value={v.value}>{v.label}</option>
                    </>
                  )
                })
              }

            </Form.Select>
          </Form.Group>

          <Form.Group autoComplete="phone-number" className="mb-3">
            <Form.Label style={{ fontSize: "0.8em" }}>Phone Number</Form.Label>
            <InputGroup>
              <InputGroup.Text>+62</InputGroup.Text>
              <Form.Control
                autoComplete={"off"}
                onChange={(e) => setPhone(e.target.value)}
                type="number"
                placeholder="Please Input Phone Number"
                value={phone}
              />
            </InputGroup>
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Age</Form.Label>
            <Radio.Group defaultValue value={age} style={{ width: '100%' }} onChange={(e) => {
              setAge(e.target.value);
            }}>
              {
                User.getListAge.map((v) => {
                  return (
                    <>
                      <Radio value={v.value}>{v.label}</Radio>
                    </>
                  )
                })
              }
            </Radio.Group>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Gender</Form.Label>
            <Radio.Group value={gender} style={{ width: '100%' }} onChange={(e) => {
              setGender(e.target.value);
            }}>
              <Radio value={"MALE"}>Male</Radio>
              <Radio value={"FEMALE"}>Female</Radio>
            </Radio.Group>
          </Form.Group>
          {/* <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Sector</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setSector(e.target.value)}
              type="text"
              placeholder="Please input Sector"
              value={sector}
            />
          </Form.Group> */}

          {/* {
            isNewRecord &&
            <>
              <Form.Group autoComplete="new-password" className="mb-3">
                <Form.Label style={{ fontSize: "0.8em" }}>Password</Form.Label>
                <Form.Control
                  autoComplete={"new-password"}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Please input Password"
                  value={password}
                />
              </Form.Group>

              <Form.Group autoComplete="confirm-password" className="mb-3">
                <Form.Label style={{ fontSize: "0.8em" }}>Confirm Password</Form.Label>
                <Form.Control
                  autoComplete={"new-password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Please Confirm Password"
                  value={confirmPassword}
                />
              </Form.Group>
            </>

          } */}

          <Form.Group autoComplete="domicile" className="mb-3" controlId="formBasicPassword">
            <Form.Label style={{ fontSize: "0.8em" }}>Domicile</Form.Label>
            <Form.Control
              autoComplete='off'
              onChange={(e) => setDomicile(e.target.value)}
              type="text"
              placeholder="Please input Domicile"
              value={domicile}
            />
          </Form.Group>
          <div className={"d-flex flex-row justify-content-end"}>
            <Button className='mr-3' size="sm" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <LoadingButton className='ml-3' size="sm" onClick={async () => { await submit() }} variant="primary">
              {isNewRecord ? 'Create' : 'Save'}
            </LoadingButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>

  )
}

export default UserModalAdd;