import {Button, CircularProgress} from "@mui/material";
import {useState} from "react";

export default function LoadingButton(props) {

  let [isLoading, setIsLoading] = useState(false)

  return <Button
    {...props}
    disabled={isLoading || props.disabled}
    onClick={async () => {
      console.log("is loading true")
      setIsLoading(true)
      await props.onClick()
      setIsLoading(false)
      console.log("is loading false")
    }}
  >
    {props.children} {isLoading && <CircularProgress className={"ms-1"} style={{color:"black"}} size={"1em"}/>}
  </Button>
}