import {

    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, message, Radio } from 'antd';
import { da } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Utils from 'src/api/utils';
import { Form, InputGroup, Modal } from "react-bootstrap";
import LoadingButton from "../../../components/reusable/LoadingButton";
import Booth from 'src/api/booth';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const BoothModal = (props) => {
    const { open, data, onClose, isNewRecord } = props;
    // console.log('hubla', data)
    const [id, setId] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [description, setDescription] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [instagramUrl, setInstagramUrl] = useState();
    const [facebookUrl, setFacebookUrl] = useState();
    const [whatsappNumber, setWhatsappNumber] = useState();

    const [messageApi, contextHolder] = message.useMessage();

    const clearData = () => {
        setId()
        setName()
        setEmail()
        setPassword()
        setConfirmPassword()
        setDescription()
        setImageUrl()
        setInstagramUrl()
        setFacebookUrl()
        setWhatsappNumber()
    }

    const handleClose = (isRefresh = true) => {
        clearData()
        onClose(isRefresh)
    }


    const formattedPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) {
            return null
        }
        let phoneFormat = phoneNumber;
        const code = phoneNumber.slice(0, 2)
        if (code == 62) {
            phoneFormat = phoneNumber.slice(2, phoneNumber.length)
        }
        return phoneFormat
    }


    const uploadImage = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const files = e.target.files[0];
        console.log('files : ', files)
        if (files.size > 2 * 1024 * 1024) {
            messageApi.open({
                type: 'error',
                content: 'The file is too big (max 2 mb)',
            });
            return;
        }
        let body = new FormData();
        body.append("upload", files, files.name);
        console.log(body)
        for (var pair of body.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        let res = await Utils.uploadImage(body);
        console.log(res)
        setImageUrl(res.location)
    }

    useEffect(() => {
        if (open) {
            setId(data?.id)
            setName(data?.name)
            setEmail(data?.email)
            setDescription(data?.description)
            setImageUrl(data?.image_url)
            setInstagramUrl(data?.instagram_url)
            setFacebookUrl(data?.facebook_url)
            setWhatsappNumber(formattedPhoneNumber(data?.whatsapp_number))
        }
    }, [data])

    const submit = async () => {

        if (!name) {
            message.error('Field name is required')
            return
        }

        if (!email) {
            message.error('Field email is required')
            return
        }

        let mailformat = /[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+(?:\.[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+)*@(?:[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?/i;
        if (!email.match(mailformat)) {
            message.error('Email is invalid')
            return
        }

        if (isNewRecord && (password != confirmPassword)) {
            message.error("Password and confirm password don't match")
            return
        }

        let body = {
            name: name,
            email: email,
            description: description,
            image_url: imageUrl,
            instagram_url: instagramUrl,
            facebook_url: facebookUrl,
            whatsapp_number: whatsappNumber && "62" + whatsappNumber,
            password: password,
        }
        // console.log(body)
        if (!data) {
            try {
                let res = await Booth.create(body);
                handleClose(true);
            } catch (error) {
                message.error(error.error_message)
            }
            return;
        }
        try {
            let res = await Booth.edit(id, body);
            handleClose(true);
        } catch (error) {
            message.error(error.error_message)
        }
        return;
    }

    return (
        <Modal className='custom-modal' show={open} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{!isNewRecord ? 'Edit' : 'Add'} Booth</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="new-password">
                    {contextHolder}
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Name</Form.Label>
                        <Form.Control
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Please enter name"
                            value={name}
                        />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Email</Form.Label>
                        <Form.Control
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            placeholder="Please enter email"
                            value={email}
                        />
                    </Form.Group>
                    {
                        isNewRecord &&
                        <>
                            <Form.Group autoComplete="new-password" className="mb-3">
                                <Form.Label style={{ fontSize: "0.8em" }}>Password</Form.Label>
                                <Form.Control
                                    autoComplete={"new-password"}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    placeholder="Please input Password"
                                    value={password}
                                />
                            </Form.Group>

                            <Form.Group autoComplete="new-password" className="mb-3">
                                <Form.Label style={{ fontSize: "0.8em" }}>Confirm Password</Form.Label>
                                <Form.Control
                                    autoComplete={"new-password"}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    type="password"
                                    placeholder="Please Confirm Password"
                                    value={confirmPassword}
                                />
                            </Form.Group>
                        </>
                    }
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Description</Form.Label>
                        <Form.Control
                            onChange={(e) => setDescription(e.target.value)}
                            as="textarea"
                            rows="4"
                            placeholder="Please enter description"
                            value={description}
                        />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Image URL</Form.Label>
                        <Form.Control
                            onChange={(e) => uploadImage(e)}
                            type="file"
                            placeholder="Please enter image url"
                            accept='image/*'
                        />
                        <Form.Label style={{ fontSize: "0.7em", fontWeight: "lighter" }}>Recommended size: 200x200 px</Form.Label>
                    </Form.Group>
                    {imageUrl &&
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={imageUrl} style={{ maxHeight: "200px", width: "auto" }} />
                        </div>
                    }
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Instagram URL</Form.Label>
                        <Form.Control
                            onChange={(e) => setInstagramUrl(e.target.value)}
                            type="text"
                            placeholder="Please enter instagram url"
                            value={instagramUrl}
                        />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Facebook URL</Form.Label>
                        <Form.Control
                            onChange={(e) => setFacebookUrl(e.target.value)}
                            type="text"
                            placeholder="Please enter facebook url"
                            value={facebookUrl}
                        />
                    </Form.Group>
                    <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.8em" }}>Whatsapp Number</Form.Label>
                        <InputGroup>
                            <InputGroup.Text>+62</InputGroup.Text>
                            <Form.Control
                                onChange={(e) => setWhatsappNumber(e.target.value)}
                                type="text"
                                placeholder="Please enter whatsapp number"
                                value={whatsappNumber}
                            />
                        </InputGroup>
                    </Form.Group>


                    <div className={"d-flex flex-row justify-content-end"}>
                        <Button className='mr-3' size="sm" onClick={handleClose} variant="primary">
                            Cancel
                        </Button>
                        <LoadingButton className='ml-3' size="sm" onClick={submit} variant="outlined">
                            {isNewRecord ? 'Create' : 'Save'}
                        </LoadingButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>

    )
}

export default BoothModal;