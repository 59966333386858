import {

    Button,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { Form, Modal } from "react-bootstrap";
import nameTagTemplate from "../../assets/nametagtemplate.png"
import { useEffect, useRef, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import logo from "../../assets/qrlogo.jpg"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment"
import pdfMake, { tableLayouts } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import printJS from "print-js";
import { message } from 'antd';
export default function ScanLocationModal(props) {
    const [messageApi, contextHolder] = message.useMessage()
    const [nameTagWidth, setNameTagWidth] = useState(0)
    const { data, onClose } = props

    const handleClose = (isRefresh = true) => {
        onClose(isRefresh)
    }

    return (
        <>
            <div style={{
                position: "fixed",
                bottom: !!data ? 30 : -800,
                right: 30,
                zIndex: 30,
                display: "flex",
                flexDirection: "column",
                transitionDuration: "0.4s"
            }}>
                {contextHolder}
                <div
                    style={{
                        width: 354,
                        height: 500,
                        background: "white",
                    }}
                    className={"d-flex flex-column"}>
                    <div
                        className={"d-flex flex-column align-items-center justify-content-center"}
                        ref={(ref) => {
                            if (ref) {
                                console.log("NTW", ref.clientWidth)
                                setNameTagWidth(ref.clientWidth)
                            }
                        }}
                        style={{
                            // background: `url(${nameTagTemplate})`,
                            backgroundSize: "contain",
                            backgroundRepeatX: "no-repeat",
                            backgroundRepeatY: "no-repeat",
                            width: "100%",
                            height: 1.4095 * nameTagWidth,
                        }}
                    >
                        <div
                            className={"d-flex flex-column align-items-center justify-content-center"}
                            style={{
                                background: "white",
                                width: 0.6 * nameTagWidth,
                                height: 0.8 * nameTagWidth,
                                borderRadius: 0.02 * nameTagWidth
                            }}
                        >

                            <div style={{
                                fontSize: nameTagWidth * 0.025,
                                fontWeight: 'bolder'
                            }}>

                            </div>

                            <div style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: nameTagWidth * 0.05,
                                marginTop: nameTagWidth * 0.01,
                                textTransform: "uppercase",
                                textAlign: "center",
                                textOverflow: "clip",
                                whiteSpace: "nowrap",
                                width: 0.6 * nameTagWidth,
                                maxWidth: "100%",
                                // overflow : "hidden"
                            }}>
                                {data?.success ? 'Success' : 'Failed'}
                            </div>
                            <div style={{
                                color: "black",
                                fontSize: nameTagWidth * 0.03,
                                marginTop: nameTagWidth * 0.0001,
                                textTransform: "uppercase"
                            }}>
                                {data?.message}
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"d-flex flex-row align-items-end justify-content-end"}>
                    <Button className='ml-3 me-2' size="sm" onClick={() => {
                        handleClose()
                    }} variant="outlined">
                        Close
                    </Button>
                    {/* <Button className='mt-2' size="sm" onClick={() => {
                    }} variant="contained">
                        Print
                    </Button> */}
                </div>
            </div>
        </>
    )
}