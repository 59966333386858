// ----------------------------------------------------------------------
const userData = localStorage?.getItem('userData') ?? null
const parseData = userData ? JSON.parse(userData) : null;
console.log('telah login parse', parseData)
const account = {
  displayName: parseData?.username,
  email: 'demo@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
