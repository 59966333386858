
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import SignupModal from 'src/components/custom/SignupModal';
import { useNavigate } from 'react-router-dom';
import ScanLocationModal from 'src/components/custom/ScanLocationModal';
import ScanLocation from 'src/api/scan-location';
import { message } from 'antd';

const QRLocation = () => {

    const NOTFOUND = 0,
        SUCCESS = 1,
        ERROR = -1

    const FC_USER_MODE = 'user',
        FC_ENVIRONTMENT = 'environment'

    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [openModal, setOpenModal] = useState(false);

    const [data, setData] = useState(null);

    const [users, setUsers] = useState([]);
    const [openDialog, setOpenDialog] = useState(false)
    const [isOpenSignupModal, setIsOpenSignupModal] = useState(false)

    const [scanStatus, setScanStatus] = useState(0)
    const [scanMessage, setScanMessage] = useState(null)
    const [resultScan, setResultScan] = useState()
    const [facingMode, setFacingMode] = useState(FC_USER_MODE)

    const [qrData, setQRData] = useState(null)

    const [isFullscreen, setFullscreen] = useState(false)

    const navigate = useNavigate()

    const handleResult = async (qrData) => {
        try {
            let result = await ScanLocation.scan(qrData.text)
            if (result?.name) {
                setQRData({
                    success: true,
                    message: 'Location successfully scanned'
                })
            }

        } catch (e) {
            if (e.code === "NO_USER_DATA") {
                setQRData({
                    success: false,
                    message: 'Please re-Login'
                })
            }
        }
    }

    const handeMessage = () => {
        let obj = {}
        if (scanStatus === SUCCESS) {
            obj = {
                title: 'Success',
                color: 'green',
                message: 'Checkin successfully'
            }
        } else if (scanStatus === NOTFOUND) {
            obj = {
                title: 'Not Found',
                color: 'yellow',
                message: 'User not found'
            }
        } else {
            obj = {
                title: 'Error',
                color: 'red',
                message: "Invalid QR"
            }
        }
        return obj
    }

    return (
        <div id="fullscreenElement">
            <QrReader
                onResult={(result, error) => {
                    if (!!result) {
                        handleResult(result)
                        // setOpenDialog(true)
                    }

                    if (!!error) {

                    }

                }}
                ViewFinder={() => {
                    return (
                        <>
                            {/*<div className={isFullscreen ? 'crosshair-full' : 'crosshair'}></div>*/}
                        </>
                    )
                }}
                constraints={{ facingMode: facingMode }}
                style={{
                    /* marginTop: "-10vh" */
                }}
                videoStyle={{ maxWidth: '100vw', height: "100vh", objectFit: "cover", position: "fixed" }}
            />
            <div style={{
                maxHeight: "100vh",
                maxWidth: "100vw",
                position: "fixed",
                zIndex: 20,
                top: 0,
                left: 0
            }}
            >
                <div style={{
                    background: "#00000080",
                    height: "35vh",
                    width: "100vw"
                }}>

                </div>
                <div
                    className={"d-flex flex-row"}
                    style={{
                        // background : "#00000080",
                        height: "30vh",
                        width: "100vw"
                    }}>
                    <div style={{
                        flex: 1,
                        background: "#00000080",
                    }}>

                    </div>
                    <div style={{
                        height: "100%",
                        width: "30vh",
                        border: "7px solid black"
                    }}>

                    </div>
                    <div style={{
                        flex: 1,
                        background: "#00000080",
                    }}>

                    </div>
                </div>
                <div
                    className={"d-flex flex-column align-items-center justify-content-start"}
                    style={{
                        background: "#00000080",
                        height: "35vh",
                        width: "100vw",
                        paddingRight: "2vw",
                        paddingLeft: "2vw",
                        paddingTop: 20
                    }}>

                    <div style={{ color: "white", textAlign: "center" }}>
                        Please scan your QR here.
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        {/* <div style={{ marginTop: "1em", color: "white" }}>Don't have an account?</div>
            <Button variant={"contained"} onClick={() => setIsOpenSignupModal(true)}>
              Click here to sign up
            </Button> */}
                        {/* <Button className='mt-4' variant='contained' onClick={() => navigate("/qr-ir-scanner")}>
              Use IR scanner
            </Button> */}
                    </div>
                </div>
            </div>

            <SignupModal
                isOpen={isOpenSignupModal}
                onClose={() => setIsOpenSignupModal(false)}
                setUuid={uuid => handleResult(uuid)}
            />

            <ScanLocationModal
                data={qrData}
                onClose={() => {
                    setQRData(null)
                }}
            />

        </div>
    )
}

export default QRLocation