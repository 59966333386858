import ApiRequest from "../utils/apiRequest"

export default class SessionUser {
    static getBookmarkList = async (id) => {
        return await ApiRequest.set("v1/session/bookmark/" + id, "GET")
    }

    static getUsersBySessionId = async (id) => {
        return await ApiRequest.set("v1/session_user_form/session/"+id, "GET")
    }

    static getAll = async () => {
        return await ApiRequest.set("v1/session_user_forms", "GET")
    }

    static scanAction = async (id, body) => {
        return await ApiRequest.set("v1/session_user/checkin/"+id, "POST", body)
    }

    static create = async (body) => {
        return await ApiRequest.set("v1/session", "POST", body)
    }

    static edit = async (id, body) => {
        return await ApiRequest.set("v1/session/" + id, "PUT", body)
    }

    static delete = async (id) => {
        return await ApiRequest.set("v1/session/" + id, "DELETE")
    }
}