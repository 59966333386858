import {

  Button,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { Form, Modal, InputGroup } from "react-bootstrap";
import nameTagTemplate from "../../assets/nametagtemplate.png"
import { useRef, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import logo from "../../assets/qrlogo.jpg"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment"

import pdfMake, { tableLayouts } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import printJS from "print-js";
import { DatePicker, message } from 'antd';
import zIndex from '@mui/material/styles/zIndex';
import { createPortal } from 'react-dom';
import User from 'src/api/user';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const textfieldStyle = {
  borderTopRightRadius: 1000,
  borderBottomRightRadius: 1000,
  borderTopLeftRadius: 1000,
  borderBottomLeftRadius: 1000,
}

const radiolabelStyle = {
  marginLeft: "0.5em",
  fontSize: "0.8em"
}

export default function SignupModal({ isOpen, setUuid, onClose }) {

  const printContainerRef = useRef(null)

  const [name, setName] = useState()
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState()
  const [dob, setDob] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [gender, setGender] = useState()
  const [messageApi, contextHolder] = message.useMessage();
  const handleClose = (isRefresh = true) => {
    setName()
    setPhone()
    setEmail()
    setPassword()
    setConfirmPassword()
    setDob()
    setGender()
    onClose(isRefresh)
  }

  const submit = async () => {
    if (!name) {
      messageApi.error('Field Name is required')
      return
    }

    if (!email) {
      messageApi.error('Field Email is required')
      return
    }

    if (!password) {
      messageApi.error('Field Password is required')
      return
    }

    if (!confirmPassword) {
      messageApi.error('Field Confirm Password is required')
      return
    }

    if (password != confirmPassword) {
      messageApi.error("Password and Confirm Password don't match")
      return
    }

    if (!gender) {
      messageApi.error('Field Gender is required')
      return
    }

    if (!dob) {
      messageApi.error('Field DOB is required')
      return
    }

    if (!phone) {
      messageApi.error('Field Phone Number is required')
      return
    }

    let body = {
      name: name,
      gender: gender,
      email: email,
      dob: dob,
      phone_num: phone,
    }

    console.log(body)

    let res = await User.create(body);
    console.log(res)
    if (res?.success || res?.id) {
      messageApi.success('Successfully add user')
    } else {
      messageApi.error('Failed update user')
    }
    setUuid({ text: res.uuid })
    return;
  }

  return (
    <div style={{ width: "100vw", height: "100vh", background: "white" }}>
      <div style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: 30,
        display: isOpen ? "flex" : "none",
        flexDirection: "column",
        transitionDuration: "1s",
        transform: 'translate(-50%, -50%)',
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: 'center',
      }}
        id="registerModal">
        {contextHolder}
        <div
          style={{
            width: 354,
            height: "auto",
            background: "white",
          }}
          className={"d-flex flex-column"}
          ref={printContainerRef}
        >
          <div style={{ paddingTop: "2em", paddingLeft: "1em" }}>
            <div>
              Hello there!
            </div>
            <div style={{ fontSize: "0.8em" }}>
              Please fill out this registration form
            </div>
          </div>
          <form autoComplete='new-password'
            style={{ padding: "2em", accentColor: "black", paddingTop: "1em" }}
          >
            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
              <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Name</Form.Label>
              <Form.Control
                autoComplete='new-password'
                type="text"
                value={name}
                className='rounded-full'
                style={textfieldStyle}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
              <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Phone</Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Text id="basic-addon1" style={{ borderTopLeftRadius: 1000, borderBottomLeftRadius: 1000, }}>+62</InputGroup.Text>
                <Form.Control
                  autoComplete='new-password'
                  type="number"
                  value={phone}
                  className=''
                  style={{
                    borderTopRightRadius: 1000,
                    borderBottomRightRadius: 1000,
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </InputGroup>

            </Form.Group>
            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
              <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Email</Form.Label>
              <Form.Control
                autoComplete='new-password'
                type="text"
                value={email}
                className='rounded-full'
                style={textfieldStyle}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
              <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Password</Form.Label>
              <Form.Control
                autoComplete='new-password'
                type="password"
                value={password}
                className='rounded-full'
                style={textfieldStyle}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
              <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Confirm Password</Form.Label>
              <Form.Control
                autoComplete='new-password'
                type="password"
                value={confirmPassword}
                className='rounded-full'
                style={textfieldStyle}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{ display: "flex", flexDirection: "column" }}>
              <Form.Label style={{ fontSize: "0.8em", paddingLeft: "1.2em", }}>Date of Birth</Form.Label>
              <DatePicker
                format={'DD/MM/YYYY'}
                style={textfieldStyle}
                popupStyle={{ zIndex: 10000 }}
                value={dob}
                onChange={(date) => setDob(date)}
                /* panelRender={(panelNode) => {createPortal(panelNode, document.body)}} */
                getPopupContainer={triggeredNode => {
                  createPortal(triggeredNode, /* document.getElementById("fullscreenElement") */ document.body)
                  console.log(triggeredNode)
                }}
              />
            </Form.Group>
            <dialog>a</dialog>
            {/* {createPortal()} */}
            <Form.Group autoComplete="new-password" className="mb-3" controlId="formBasicPassword" style={{}}>
              <label style={{ fontSize: "0.8em", paddingLeft: "1.2em", marginBottom: "0.5em" }}>Gender</label>
              <div className="flex items-start flex-col mb-2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input id="default-checkbox"
                    name="gender"
                    type="radio"
                    value="MALE"
                    onClick={
                      (e) => {
                        console.log(e.target.value)
                        setGender(e.target.value)
                      }}
                    className="accent-intelBlack w-4 h-4 outline-none text-intelOrange bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  /* checked={checked} */
                  />
                  <label htmlFor="default-checkbox" style={radiolabelStyle}>Male</label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input id="default-checkbox"
                    name="gender"
                    type="radio"
                    value="FEMALE"
                    onClick={
                      (e) => {
                        console.log(e.target.value)
                        setGender(e.target.value)
                      }}
                    className="accent-intelBlack w-4 h-4 outline-none text-intelOrange bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  /* checked={checked} */
                  />
                  <label htmlFor="default-checkbox" style={radiolabelStyle}>Female</label>
                </div>
              </div>
            </Form.Group>
          </form>
          <Button className='my-2 mx-5' size="sm" style={{ ...textfieldStyle, borderColor: "black", border: "0.1em solid", color: "black", }} onClick={() => {
            submit()
            // generatePDFV2()
          }}
          >
            Submit
          </Button>
          <Button className='my-2 mx-5' size="sm" style={{ color: "black", textDecoration: "underline", textUnderlineOffset: 2 }} onClick={() => {
            handleClose()
            // generatePDFV2()
          }}
          >
            Cancel
          </Button>
        </div>
        {/* <div className={"d-flex flex-row align-items-end justify-content-end"}>
        <Button className='mt-2' size="sm" onClick={() => {
          handleClose()
          // generatePDFV2()
        }} variant="contained">
          Close
        </Button>
      </div> */}
      </div>
    </div>
  )
}
