import { Helmet } from 'react-helmet-async';
import { filter, result } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Modal } from 'react-bootstrap';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import moment from "moment";
import palette from 'src/utils/palette';
import UserModal from 'src/sections/@dashboard/user/UserModal';
import Admin from 'src/api/admin';
import { QrReader } from 'react-qr-reader';
import { message } from 'antd';
import dayjs from 'dayjs'
import UserTagModal from "../components/custom/UserTagModal";
import Portal from 'react-overlays/Portal'
import UserTagModalV2 from "../components/custom/UserTagModalV2";
import SignupModal from 'src/components/custom/SignupModal';
import { useNavigate, useParams } from 'react-router-dom';
import Session from 'src/api/session';
import SessionUser from 'src/api/sessionuser';
import ScanLocation from 'src/api/scan-location';

const QRKioskLocation = () => {
  const { id } = useParams();
  const NOTFOUND = 0,
    SUCCESS = 1,
    ERROR = -1

  const FC_USER_MODE = 'user',
    FC_ENVIRONTMENT = 'environment'

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openModal, setOpenModal] = useState(false);

  const [data, setData] = useState(null);

  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false)
  const [isOpenSignupModal, setIsOpenSignupModal] = useState(false)

  const [scanStatus, setScanStatus] = useState(0)
  const [scanMessage, setScanMessage] = useState(null)
  const [resultScan, setResultScan] = useState()
  const [facingMode, setFacingMode] = useState(FC_USER_MODE)

  const [qrData, setQRData] = useState(null)

  const [isFullscreen, setFullscreen] = useState(false)

  const [sessionData, setSessionData] = useState(null);
  const [scanResult, setScanResult] = useState(null);

  const navigate = useNavigate()

  const handleResult = async (qrData) => {
    try {
      console.log("ID Session", id, "UUID", qrData.text)
      let body = {
        uuid: qrData.text,
        scan_location_id: +id,
      }
      let result = await ScanLocation.scanUser(body)
      console.log(result)
      setScanResult(result)
    } catch (e) {
      setScanStatus(ERROR)
      setQRData(null)
    }
  }

  const handeMessage = () => {
    let obj = {}
    if (scanStatus === SUCCESS) {
      obj = {
        title: 'Success',
        color: 'green',
        message: 'Checkin successfully'
      }
    } else if (scanStatus === NOTFOUND) {
      obj = {
        title: 'Not Found',
        color: 'yellow',
        message: 'User not found'
      }
    } else {
      obj = {
        title: 'Error',
        color: 'red',
        message: "Invalid QR"
      }
    }
    return obj
  }

  const fetchSessionData = async () => {
    let res = await Session.getById(id)
    setSessionData(res)
  }

  useEffect(() => {
    //fetchSessionData()
  }, [])

  useEffect(() => {
    if(qrData && !scanResult) handleResult(qrData)
  }, [qrData])

  return ( 
    <div id="fullscreenElement">
      <QrReader
        onResult={(result, error) => {
          console.log('handling result', result)
          if (!!result) {
            setQRData(result)   
            // setOpenDialog(true)
          }

          if (!!error) {

          }

        }}
        ViewFinder={() => {
          return (
            <>
              {/*<div className={isFullscreen ? 'crosshair-full' : 'crosshair'}></div>*/}
            </>
          )
        }}
        constraints={{ facingMode: facingMode }}
        style={{
          /* marginTop: "-10vh" */
        }}
        videoStyle={{ maxWidth: '100vw', height: "100vh", objectFit: "cover", position: "fixed" }}
      />
      <div style={{
        maxHeight: "100vh",
        maxWidth: "100vw",
        position: "fixed",
        zIndex: 20,
        top: 0,
        left: 0
      }}
      >
        <div style={{
          background: "#00000080",
          height: "35vh",
          width: "100vw"
        }}>

        </div>
        <div
          className={"d-flex flex-row"}
          style={{
            // background : "#00000080",
            height: "30vh",
            width: "100vw"
          }}>
          <div style={{
            flex: 1,
            background: "#00000080",
          }}>

          </div>
          <div style={{
            height: "100%",
            width: "30vh",
            border: "7px solid black"
          }}>

          </div>
          <div style={{
            flex: 1,
            background: "#00000080",
          }}>

          </div>
        </div>
        <div
          className={"d-flex flex-column align-items-center justify-content-start"}
          style={{
            background: "#00000080",
            height: "35vh",
            width: "100vw",
            paddingRight: "2vw",
            paddingLeft: "2vw",
            paddingTop: 20
          }}>

          <div style={{ color: "white", textAlign: "center" }}>
            {/* Welcome to {sessionData?.title}<br /> */}
            Please scan your QR here.
          </div>

          {/* <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ marginTop: "1em", color: "white" }}>Don't have an account?</div>
            <Button variant={"contained"} onClick={() => setIsOpenSignupModal(true)}>
              Click here to sign up
            </Button>
            <Button className='mt-4' variant='contained' onClick={() => navigate("/qr-ir-scanner")}>
              Use IR scanner
            </Button>
          </div> */}
        </div>
      </div>

{/*       <SignupModal
        isOpen={isOpenSignupModal}
        onClose={() => setIsOpenSignupModal(false)}
        setUuid={uuid => handleResult(uuid)}
      />

      <UserTagModalV2
        data={qrData}
        onClose={() => {
          setQRData(null)
        }}
      /> */}

      <Modal show={!!scanResult} onHide={() => {setScanResult(null); setQRData(null)}} style={{zIndex: 1111111}}>
        <Modal.Header closeButton />
        <Modal.Body>{scanResult?.http_code? "Scan failed" : "Scan successful"}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setScanResult(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default QRKioskLocation