import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import AdminPage from './pages/AdminPage';
import QRScanner from './pages/QRScanner';
import QRScanner2 from './pages/QRScanner2';
import LogoutPage from "./pages/LogoutPage";
import QRScannerFull from './pages/QRScannerFull';
import QRKiosk from './pages/QRKiosk';
import QRLocation from './pages/QRLocation';
import BoothPage from './pages/BoothPage';
import QRScannerWithIR from './pages/QRScannerWithIR';
import ProfilePage from './pages/ProfilePage';
import SessionPage from './pages/SessionPage';
import ScanLocationPage from './pages/ScanLocationPage';
import SessionPageV2 from './pages/SessionPageV2';
import SessionUserPage from './pages/SessionUserPage';
import QRKioskSession from './pages/QRKioskSession';
import QRKioskLocation from "./pages/QRKioskLocation";
import ScannedUserPage from './pages/ScannedUserPage';
// import QRKioskLocation from './pages/QRKioskLocation';

// ----------------------------------------------------------------------

export default function Router() {


  let isLoggedIn = !!localStorage.getItem("token")
  console.log('is login', isLoggedIn)
  console.log("TOLKIEN", localStorage.getItem("token"), isLoggedIn)

  let tempRoutes = [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '', element: <DashboardAppPage /> },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'app', element: <DashboardAppPage /> },
        // { path: 'admin', element: <AdminPage /> },
        { path: 'booth', element: <BoothPage /> },
        { path: 'coaching-clinic', element: <SessionPageV2 /> },
        { path: 'user', element: <UserPage /> },
        { path: 'dashboard', element: <UserPage /> },
        { path: 'scan-location', element: <ScanLocationPage /> },
        { path: 'session_user/:id', element: <SessionUserPage /> },
        { path: 'scanned-users', element: <ScannedUserPage /> },
        // { path: 'scan-location/:id', element: <QRKioskLocation /> },

        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
        // { path: 'profile', element: <ProfilePage /> },

        
      ],
    },
    { path: 'qr', element: <QRScanner /> },
    { path: 'qr2', element: <QRScanner2 /> },
    { path: 'qr-location', element: <QRLocation /> },
    { path: 'scan-location/:id', element: <QRKioskLocation /> },
    {
      path: 'qr-kiosk/:id',
      element: <QRKioskSession />,
    },
    // {
    //   path: 'qr-ir-scanner',
    //   element: <QRScannerWithIR />,
    // },
    {
      path: 'logout',
      element: <LogoutPage />,
    },
    {
      path: 'qr-full',
      element: <QRScannerFull />,
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/app" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '*',
      element: <Navigate to="/dashboard" />,
    },
  ]

  if (!isLoggedIn) {
    tempRoutes = [
      {
        path: '*',
        element: <LoginPage />,
        // children: [
        //   { element: <Navigate to="/dashboard/app" />, index: true },
        //   { path: '404', element: <Page404 /> },
        //   { path: '*', element: <Navigate to="/404" /> },
        // ],
      },
    ]
  }

  console.log("routes", tempRoutes)

  const routes = useRoutes(tempRoutes);

  return routes;
}
